<template>
  <div class="px-6 py-2 flex flex-col font-semibold">
    <header class="py-2 text-lg flex text-text-alternate-1 pr-6">
      <button @click="handlePrev">
        <LeftArrowIcon class="h-6 fill-current" />
      </button>
      <h2 class="mx-auto">Setting</h2>
    </header>
    <ul>
      <li class="my-6 py-1 flex items-center">
        <FeatherIcon class="mx-auto h-5 fill-current" style="fill: #577387" />
        <router-link
          class="flex-grow text-text-alternate-1 pl-4"
          :to="{ name: 'Personalisation' }"
          >Personalisation</router-link
        >
        <ChevronDownIcon class='h-6 fill-current transform -rotate-90' />
      </li>
      <li class="my-6 py-1 flex items-center">
        <Lock2Icon class="mx-auto h-5 stroke-2 stroke-current" style="stroke: #577387" />
        <router-link class="flex-grow text-text-alternate-1 pl-3.5" :to="{ name: 'Security'}">Security</router-link>
        <ChevronDownIcon class='h-6 fill-current transform -rotate-90' />
      </li>
      <li class="my-6 py-1 flex items-center">
        <NotificationIcon class="mx-auto h-5 stroke-2 stroke-current" style="stroke: #577387" />
        <router-link class="flex-grow text-text-alternate-1 pl-3.5" :to="{ name: 'Notifications' }">Notifications</router-link>
        <ChevronDownIcon class='h-6 fill-current transform -rotate-90' />
      </li>
      <li class="my-6 py-1 flex items-center">
        <MegaPhoneIcon class="h-5 stroke-current stroke-2 text-text-alternate-4" style="stroke: #577387" />
        <router-link class="flex-grow text-text-alternate-1 pl-3.5" :to="{ name: 'Refer' }">Refer & Earn</router-link>
        <ChevronDownIcon class='h-6 fill-current transform -rotate-90' />
      </li>
      <li class="my-6 py-1 flex items-center">
        <MegaPhoneIcon class="h-5 stroke-current stroke-2 text-text-alternate-4" style="stroke: #577387" />
        <router-link class="flex-grow text-text-alternate-1 pl-3.5" :to="{ name: 'PromoteAndEarn' }">Promote & Earn</router-link>
        <ChevronDownIcon class='h-6 fill-current transform -rotate-90' />
      </li>
      <li class="my-6 py-1 flex items-center">
        <InfoIcon class="h-5 stroke-current stroke-2 text-text-alternate-4" style="stroke: #577387" />
        <router-link class="flex-grow text-text-alternate-1 pl-3.5" :to="{ name: 'FAQs' }">FAQs</router-link>
        <ChevronDownIcon class='h-6 fill-current transform -rotate-90' />
      </li>
      <li class="my-6 py-1 flex items-center">
        <PhoneIcon class="mx-auto h-5 stroke-2 stroke-current" style="stroke: #577387" />
        <router-link class="flex-grow text-text-alternate-1 pl-3.5" :to="{ name: 'ComposeMessage' }">Contact Us</router-link>
        <ChevronDownIcon class='h-6 fill-current transform -rotate-90' />
      </li>
    </ul>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
  name: 'Settings',

  setup() {
    const router = useRouter();
    const store = useStore();
    const authUser = store.state.auth.authData;

    const handlePrev = () => {
      router.go(-1);
    };

    return { handlePrev, authUser };
  },
};
</script>
